import React from "react";
import "../../App.css";
import Footer from "../Footer";
import Cards from "../Cards";

export default function Projects() {
  return (
    <div>
      <h1 className="projects">PROJECTS</h1>
      <Cards />
      <Footer />
    </div>
  );
}
