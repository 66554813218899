import React from "react";
import "../../App.css";
import Footer from "../Footer";

export default function Contact() {
  return (
    <div>
      <h1 className="contact">CONTACT</h1>
      <Footer />
    </div>
  );
}
